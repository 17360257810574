/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';

export const LyricEditorWrapper = styled.div`
    white-space: pre-wrap;
    min-height: 24em;
    border: 1px solid var(--border);
    background: var(--panel-card);
    color: var(--text-soft-mute);
    border-radius: 0.5em;
    margin-bottom: 1.5em;
    padding: 2em 1.5em;
    font-size: 0.938rem;
    line-height: 1.6;

    &:focus-visible {
        outline: none;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        min-height: 22em;
        padding: 2em 1em;
    }
`;

export const StyledTextArea = styled(BaseTextArea)`
    border-top-left-radius: 0em;
    border-top-right-radius: 0em;
    min-height: 510px;
`;

export const ExpandWrapper = styled.div`
    position: absolute;
    bottom: ${(props) => (props.isExpanded ? '-16px' : '-2px')};
    right: 2px;
    color: var(--text-secondary);
    transform: ${(props) => (props.isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
    ${space};
`;
