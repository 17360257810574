/* eslint-disable no-useless-return */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useEffect, useState, useRef,
} from 'preact/hooks';
import { useForm } from 'react-hook-form';
import { Fragment } from 'preact';
import AppState from '@state';

import { putWorkspaceLyrics, deleteWorkspaceLyrics } from '@api/restricted/workspace-lyrics-api';

import useBrowserDetect from '@hooks/useBrowserDetect';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import CountDown from '@distinct-components/feedback/countDown';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Text from '@ui-kit/typography/text';

import {
  LyricEditorWrapper,
  StyledTextArea,
} from './EditCompositionLyricsStyles';

const lineBreakRegex = /\r?\n/;
const paragraphBreakRegex = /(?:\r?\n){2,}/;
const tagRegex = /^\[#.*\]$/;
const badEndLineRegex = /[^\p{L}0-9?)"']+$/gu; // Allow ? ) " '

function EditCompositionLyrics({ composition, handleEditComplete, loadComposition }) {
  const lyricChunks = composition.lyrics.text ? composition.lyrics.text.split(paragraphBreakRegex) : null;

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editingLyrics, setEditingLyrics] = useState();
  const lyricEditorRef = useRef(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isClearingLyrics, setIsClearingLyrics] = useState(false);

  const {
    register, handleSubmit, setValue, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const browser = useBrowserDetect();

  useEffect(() => {
    if (browser !== 'Firefox' || browser !== 'IE') {
      register('lyricsText', { required: true });
    }
    if (lyricChunks) {
      const lyrics = lyricChunks.join('\n\n');
      setValue('lyricsText', lyrics);
      setEditingLyrics(lyrics);
    } else {
      setValue('lyricsText', '');
    }
  }, []);

  const checkUpper = (str) => {
    const allLetters = str.replace(/[^\p{L}]/gu, ''); // Keep only letters
    const upperCase = allLetters.replace(/[^\p{Lu}]/gu, ''); // Keep only uppercase
    const diff = (upperCase.length / allLetters.length) * 100;
    return diff;
  };

  const lyricsCleanup = (lyricsText) => {
    const cleanedParagraphs = [];

    const paragraphs = lyricsText.trim().split(paragraphBreakRegex);
    paragraphs.forEach((paragraph) => {
      const cleanedLines = [];

      const lines = paragraph.split(lineBreakRegex);
      lines.forEach((l, index) => {
        let line = l.trim();
        if (index !== 0 || !tagRegex.test(line)) {
          line = line.replace(badEndLineRegex, '');
          if (checkUpper(line) > 90) {
            line = line.toLowerCase();
          }
          if (!line.startsWith('(')) {
            line = line.charAt(0).toUpperCase() + line.substring(1);
          } else {
            line = line.charAt(0) + line.charAt(1).toUpperCase() + line.substring(2);
          }
        }
        if (line) {
          cleanedLines.push(line);
        }
      });

      if (cleanedLines.length > 0) {
        cleanedParagraphs.push(cleanedLines.join('\n'));
      }
    });

    return cleanedParagraphs.join('\n\n');
  };

  const handleSaveLyrics = (data) => {
    setIsLoading(true);
    setError('');
    const lyrics = lyricsCleanup(data.lyricsText);

    if (lyrics) {
      putWorkspaceLyrics(AppState.pubOrganization.id.value, composition.id, lyrics, false)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            response.json().then(() => {
              loadComposition().then(() => {
                setIsLoading(false);
                setError('');
                handleEditComplete();
              });
            });
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    } else {
      setIsLoading(false);
      setError('Invalid lyrics');
    }
  };

  const onClickClearLyrics = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleClearLyrics = () => {
    setIsClearingLyrics(true);
    setShowDeleteConfirmation(false);

    deleteWorkspaceLyrics(AppState.pubOrganization.id.value, composition.id)
      .then((response) => {
        if (response.status === 200) {
          response.json().then(() => {
            loadComposition().then(() => {
              setIsClearingLyrics(false);
              setError('');
              handleEditComplete();
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsClearingLyrics(false);
        }
      })
      .catch((err) => {
        setIsClearingLyrics(false);
        setError(err.message);
      });
  };

  return (
    <Box>
      <form id="lyricsForm" onSubmit={handleSubmit(handleSaveLyrics)} autoComplete="off">

        <Box>
          <Text fontWeight="600" color="var(--white)">
            {composition.name}
          </Text>
          {browser === 'Firefox' || browser === 'IE'
            ? (
              <StyledTextArea
                {...register('lyricsText', { required: true })}
                placeholder="Enter Lyrics..."
                type="textarea"
                fluid
                mb="1.5em"
              />
            )
            : (
              <LyricEditorWrapper
                ref={lyricEditorRef}
                autoFocus="true"
                onInput={(e) => {
                  setValue('lyricsText', e.currentTarget.textContent, { shouldValidate: true });
                }}
                contentEditable="plaintext-only"
              >
                {editingLyrics && editingLyrics}
              </LyricEditorWrapper>
            )}
        </Box>

        {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

        <BaseButton
          mb="1.5em"
          type="submit"
          btnText="Save"
          disabled={!isValid}
          fluid
          isLoading={isLoading}
        />

      </form>

      {lyricChunks && (
      <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
        {showDeleteConfirmation ? (
          <Fragment>
            <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
            <LinkButton btnText="Yes, Delete Lyrics" color="var(--red-300)" mr="1em" onClick={handleClearLyrics} />
          </Fragment>
        ) : (
          <LinkButton
            type="button"
            color="var(--red-300)"
            spinnerVariant="danger"
            isLoading={isClearingLyrics}
            btnText="Delete Lyrics"
            onClick={onClickClearLyrics}
          />
        )}
      </Box>
      )}

    </Box>
  );
}

EditCompositionLyrics.propTypes = {
  composition: PropTypes.object.isRequired,
  handleEditComplete: PropTypes.func.isRequired,
  loadComposition: PropTypes.func.isRequired,
};

export default EditCompositionLyrics;
