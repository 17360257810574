import { orgCompositionsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgComposition(orgId, compositionUuid) {
  return refresh(async () => {
    const response = await fetch(`${orgCompositionsUrl}?${new URLSearchParams({
      org: orgId,
      comp: compositionUuid,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function putOrgCompositionIdentifiers(orgId, compId, iswc, miscData) {
  const data = {
    org: orgId,
    comp: compId,
    iswc,
    misc_data: miscData,
  };
  return refresh(async () => {
    const response = await fetch(orgCompositionsUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
