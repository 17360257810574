/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import dayjs from 'dayjs';
import AppState from '@state/AppState';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Spinner from '@ui-kit/loaders/Spinner';
import CredentialCard from '@distinct-components/cards/credentialCard/CredentialCard';
import CredentialCardGrid from '@layouts/sharedGrids/credentialCardGrid/CredentialCardGrid';

function ProfileOverlayCredentials({
  credentials,
  writer,
  isLoading,
  setCredToRevoke,
  setCredToDelete,
}) {
  const handleEditCredential = (credential) => {
    AppState.publisherEditingCredential.id.value = credential.id;
    AppState.publisherEditingCredential.recipient.value = {
      user: {
        firstName: writer.firstName,
        lastName: writer.lastName,
      },
      email: writer.email,
      suggestedSociety: null,
      suggestedIPI: null,
      suggestedISNI: null,
    };
    AppState.publisherEditingCredential.terms.value = {
      compositionsControlled: credential.terms.allWorks ? 'all' : 'some',
      hasEndDate: !!credential.terms.toDate,
      fromDate: dayjs(credential.terms.fromDate).format(),
      toDate: credential.terms.toDate ? dayjs(credential.terms.toDate).endOf('day').format() : null,
      publisherRegistrationId: credential.regId,
      hasOwnership: credential.terms.ownership > 0,
      ownershipShare: credential.terms.ownership,
      territory: credential.terms.territory,
    };
    AppState.publisherEditingCredential.requirements.value = {
      detailProvided: !!credential.commitment,
      totalWorks: credential.commitment?.totalWorks || 0,
      deliveredToDate: credential.commitment?.delivered?.external || 0,
      qualifyingPercentage: credential.commitment?.qualifyingPercentage || 100,
      allowCombineWorks: credential.commitment?.aggregate?.allowed || true,
      hasMinimum: credential.commitment?.aggregate?.minimumPercentage > 0 || false,
      minimumPercentage: credential.commitment?.aggregate?.minimumPercentage || 0,
    };
    route(`/publisher/${AppState.pubOrganization.uuid.value}/songwriters/invite`);
  };

  return (
    <Box mt="1.5em">
      {isLoading ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="260px">
            <Spinner size="1.25em" variant="page" />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb="2em">
            <Text mb="0.75em">
              Active Credentials:
              {' '}
              {(credentials.filter((c) => c.isActive)).length}
            </Text>
            <CredentialCardGrid>
              {credentials.filter((c) => c.isActive).map((credential) => (
                <CredentialCard
                  credential={credential}
                  title="Songwriter Agreement"
                  handleEdit={() => handleEditCredential(credential)}
                  handleRevoke={() => setCredToRevoke(credential)}
                  bodyHeight="20.5rem"
                />
              ))}
            </CredentialCardGrid>
          </Box>
          <Box mb="2em">
            {credentials.filter((c) => !c.isActive).length > 0 && (
            <Text mb="0.75em">
              Inactive Credentials:
              {' '}
              {(credentials.filter((c) => !c.isActive)).length}
            </Text>
            )}
            <CredentialCardGrid>
              {credentials.filter((c) => !c.isActive).map((credential) => (
                <CredentialCard
                  credential={credential}
                  title="Songwriter Agreement"
                  handleDelete={() => setCredToDelete(credential)}
                  bodyHeight="20.5rem"
                />
              ))}
            </CredentialCardGrid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

ProfileOverlayCredentials.propTypes = {
  credentials: PropTypes.array.isRequired,
  writer: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setCredToRevoke: PropTypes.func.isRequired,
  setCredToDelete: PropTypes.func.isRequired,
};

export default ProfileOverlayCredentials;
