/* eslint-disable no-useless-return */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { putOrgCompositionIdentifiers } from '@api/restricted/org-composition-api';

import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

function EditCompositionIdentifiers({ composition, handleEditComplete, loadComposition }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [iswc, setIswc] = useState(composition?.iswc || '');
  const [ccli, setCcli] = useState(composition?.miscData?.ccli || '');

  const formatISWC = (iswcString) => {
    if (!iswcString) {
      return null;
    }
    const digits = iswcString.replaceAll(/[^0-9]/g, '');
    if (digits.length !== 10) {
      return null;
    }
    return `T${digits}`;
  };

  const onClickSaveIdentifiers = () => {
    setError(null);

    const inputISWC = iswc?.trim();
    const formattedISWC = inputISWC ? formatISWC(inputISWC) : null;
    if (inputISWC && !formattedISWC) {
      setError('ISWC should be the letter T followed by 10 digits');
      return;
    }

    setIsLoading(true);
    setError('');

    putOrgCompositionIdentifiers(AppState.pubOrganization.id.value, composition.id, formattedISWC, ccli ? { ccli: ccli.trim() } : {})
      .then((response) => {
        if (response.status === 200) {
          loadComposition().then(() => {
            setIsLoading(false);
            setError('');
            handleEditComplete();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <Box>

      <InputLabel label="ISWC" />
      <BaseInput
        id="iswc"
        name="iswc"
        value={iswc}
        onChange={(e) => setIswc(e.target.value)}
        placeholder=""
        type="text"
        maxLength="15" // T-000.000.000-0
        minLength="10" // 0000000000
        fluid
        mb="1.5rem"
      />

      <InputLabel label="CCLI" />
      <BaseInput
        id="ccli"
        name="ccli"
        placeholder=""
        value={ccli}
        onChange={(e) => setCcli(e.target.value)}
        type="text"
        fluid
        mb="1.5rem"
      />

      {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
      )}

      <BaseButton mb="1.5em" type="submit" btnText="Save" onClick={onClickSaveIdentifiers} fluid isLoading={isLoading} />

    </Box>
  );
}

EditCompositionIdentifiers.propTypes = {
  composition: PropTypes.object.isRequired,
  handleEditComplete: PropTypes.func.isRequired,
  loadComposition: PropTypes.func.isRequired,
};

export default EditCompositionIdentifiers;
