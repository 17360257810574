import PropTypes from 'prop-types';
import { useRef, useState } from 'preact/hooks';

import useClickOutside from '@hooks/useClickOutside';

import Spinner from '@ui-kit/loaders/Spinner';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import ShareIcon from '@assets/icons/share-inbox.svg';
import AddIcon from '@assets/icons/add-solo.svg';
import ChevronIcon from '@assets/icons/chevron-down.svg';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import MenuIcon from '@assets/icons/menu-dots.svg';
import EditIcon from '@assets/icons/edit-line.svg';
import PopoverMenu from '@ui-kit/menu/popoverMenu';
import MenuItem from '@ui-kit/menu/menuItem';

import StyledUtilityButton from './UtilityButtonStyles';

function UtilityButton({
  isLoading,
  isDisabled,
  fluid,
  onClick,
  btnText,
  width,
  minWidth,
  height,
  maxHeight,
  minHeight,
  icon,
  iconSize,
  iconTransform,
  prefixDecoration,
  suffixDecoration,
  menuOptions,
  showWarningChip,
  showSuccessChip,
  showExceptionChip,
  prefixIconSize,
  suffixIconSize,
  suffixIconTransform,
  ...restProps
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  useClickOutside(menuRef, () => {
    setTimeout(() => {
      if (showMenu) {
        setShowMenu(false);
      }
    }, 250);
  });

  const handleUtilityButtonClick = () => {
    if (menuOptions) {
      setShowMenu(!showMenu);
    } else if (onClick) {
      onClick();
    }
  };

  const onClickMenuItem = (action) => {
    action();
    setShowMenu(false);
  };

  return (
    <Box position="relative">
      <StyledUtilityButton
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading}
        fluid={fluid}
        width={width}
        minWidth={minWidth}
        height={height}
        minHeight={minHeight}
        maxHeight={maxHeight}
        onClick={handleUtilityButtonClick}
        icon={icon}
        {...restProps}
      >
        {isLoading
          ? <Spinner mono size="1.125em" width="2px" />
          : (

            <Box display="flex" justifyContent="center" alignItems="center">
              { icon
                ? (
                  <Box>
                    <Icon size={iconSize} pt="3px" color="var(--text-primary)" pointerEvents="none" style={{ transform: iconTransform }}>
                      {(() => {
                        switch (icon) {
                          case 'close':
                            return <CloseIcon />;
                          case 'share':
                            return <ShareIcon />;
                          case 'arrow-left':
                            return <ArrowLeftIcon />;
                          case 'menu':
                            return <MenuIcon />;
                          case 'edit':
                            return <EditIcon />;
                          default:
                            return null;
                        }
                      })()}
                    </Icon>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    {prefixDecoration && (
                    <Icon mr="0.5rem" size={prefixIconSize} color="var(--pill-icon-bg)" pointerEvents="none">
                      {(() => {
                        switch (prefixDecoration) {
                          case 'add':
                            return <AddIcon />;
                          case 'share':
                            return <ShareIcon />;
                          default:
                            return null;
                        }
                      })()}
                    </Icon>
                    )}
                    <Box>
                      { btnText }
                    </Box>
                    {suffixDecoration && (
                    <Icon ml="0.5rem" size={suffixIconSize} color="var(--pill-icon-bg)" pointerEvents="none" style={{ transform: suffixIconTransform }}>
                      {(() => {
                        switch (suffixDecoration) {
                          case 'chevron':
                            return <ChevronIcon />;
                          case 'share':
                            return <ShareIcon />;
                          default:
                            return null;
                        }
                      })()}
                    </Icon>
                    )}
                  </Box>
                )}
            </Box>
          )}
      </StyledUtilityButton>
      {showMenu
      && (
      <PopoverMenu ref={menuRef} width="10em" top="22px" right="-2px" padding="0.875rem">
        {menuOptions.map((item) => (
          <MenuItem
            isDestructive={item.isDestructive || false}
            menuText={item.label}
            onClick={() => onClickMenuItem(item.action)}
          />
        ))}
      </PopoverMenu>
      )}
    </Box>
  );
}

UtilityButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fluid: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  decoration: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconTransform: PropTypes.string,
  prefixDecoration: PropTypes.string,
  suffixDecoration: PropTypes.string,
  prefixIconSize: PropTypes.string,
  suffixIconSize: PropTypes.string,
  suffixIconTransform: PropTypes.string,
  menuOptions: PropTypes.array,
  showWarningChip: PropTypes.bool,
  showSuccessChip: PropTypes.bool,
  showExceptionChip: PropTypes.bool,
};

UtilityButton.defaultProps = {
  isLoading: null,
  isDisabled: null,
  fluid: null,
  btnText: null,
  onClick: null,
  width: 'auto',
  minWidth: 'auto',
  maxHeight: null,
  minHeight: null,
  height: null,
  decoration: null,
  icon: null,
  iconSize: '0.65rem',
  iconTransform: 'translate(0,0)',
  prefixDecoration: null,
  suffixDecoration: null,
  prefixIconSize: '0.58rem',
  suffixIconSize: '0.58rem',
  suffixIconTransform: 'translateY(-1px)',
  menuOptions: null,
  showWarningChip: false,
  showSuccessChip: false,
  showExceptionChip: false,
};

export default UtilityButton;
