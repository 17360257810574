/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';

function SelectCredentialEditType({
  setStep,
  editType,
  setEditType,
  ...restProps
}) {
  const [selectedType, setSelectedType] = useState('update');

  useEffect(() => {
    if (editType) {
      setSelectedType(editType);
    }
  }, []);

  const onClickContinue = () => {
    setEditType(selectedType);
    setStep('terms');
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      <Box mb="1.25em">
        <Box>
          <Text fontWeight="600" mb="0.25em">Apply Changes to:</Text>
        </Box>
        <BaseSelect
          id="editType"
          name="editType"
          fluid
          defaultValue={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="update">All Compositions Previously Submitted</option>
          <option value="new">Only New Compositions Moving Forward</option>
        </BaseSelect>

      </Box>

      <BreakLine mt="1.5em" color="var(--panel-info-border)" mb="1.5em" />

      <Box display="flex" justifyContent="flex-end">
        <BaseButton
          mb="1.5em"
          btnText="Continue"
          onClick={onClickContinue}
          fluid
          decoration="arrow"
        />
      </Box>

    </Box>
  );
}

SelectCredentialEditType.propTypes = {
  setStep: PropTypes.func.isRequired,
  editType: PropTypes.string,
  setEditType: PropTypes.func.isRequired,
};

SelectCredentialEditType.defaultProps = {
  editType: '',
};

export default SelectCredentialEditType;
