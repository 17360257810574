import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import AppState from '@state';
import { Fragment } from 'preact';

import loadAccountProfile from '@actions/loadAccountProfile';
import loadComposition from '@actions/loadComposition';
import loadWorkspaceAccounts from '@actions/loadWorkspaceAccounts';

import { postWorkspaceConfirm } from '@api/restricted/workspace-confirm-api';

import usePollForData from '@hooks/usePollForData';

import SoloLogo from '@assets/logos/logo-symbol.svg';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Box from '@ui-kit/box';
import BreakLine from '@ui-kit/dividers/breakLine';
import Header from '@ui-kit/typography/header';
import Icon from '@ui-kit/icon';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Spinner from '@ui-kit/loaders/Spinner';
import Tabs from '@ui-kit/tabs/Tabs';
import Text from '@ui-kit/typography/text';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import UtilityButton from '@ui-kit/buttons/utilityButton';

import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import WorkspaceRecordingSection from '@components/shared-composition-components/workspaceRecordingSection';
import StructuredLyrics from './sections/structuredLyrics';
import CompositionDetailsSection from './sections/detailsSection/CompositionDetailsSection';
import CompositionWriterShareSection from './sections/writersShareSection/CompositionWriterShareSection';
import ActivitiesSection from './sections/activitiesSection';
import CompositionInterpolationSection from './sections/interpolationSection/CompositionInterpolationSection';
import WorkspaceFileSection from './sections/workspaceFileSection';
import CompositionOwnershipActions from './sections/compositionOwnershipActions/OwnershipActions';

import LeaveWorkspace from './sections/leaveWorkspace';
import DeleteWorkspace from './sections/deleteWorkspace';
import ShareWorkspace from './sections/shareWorkspace';

import {
  WorkspaceWrapper,
  HeaderWrapper,
  HeaderDetails,
  HeaderActions,
  NavigationWrapper,
  NavigationTabs,
  PageWrapper,
  PageContent,
  WorkItemsWrapper,
  MobileHeaderContent,
  HeaderContent,
  MobileTitleWrapper,
  ProofWrapper,
} from '../../../../styles/sharedStyledComponents/workspaceSharedStyledComponents';

function CompositionWorkspace({ id }) {
  const [showJoinWorkspace, setShowJoinWorkspace] = useState(false);
  const [workspacePersona, setWorkspacePersona] = useState(null);
  const [acceptSuggestedIPI, setAcceptSuggestedIPI] = useState(true);
  const [acceptSuggestedSociety, setAcceptSuggestedSociety] = useState(true);
  const [isJoining, setIsJoining] = useState(false);
  const [joinWorkspaceError, setJoinWorkspaceError] = useState(null);
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true);
  const [workspaceTab, setWorkspaceTab] = useState('Main');
  const isChildEditing = AppState.workspace.isEditingChild.value;
  const workspaceId = parseInt(id, 10);
  const [showLeaveWorkspace, setShowLeaveWorkspace] = useState(false);
  const [showDeleteWorkspace, setShowDeleteWorkspace] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [showShareWorkspace, setShowShareWorkspace] = useState(false);
  const [menuActions, setMenuActions] = useState([]);

  const onToggleLeaveWorkspace = () => {
    setShowLeaveWorkspace(!showLeaveWorkspace);
  };

  const onToggleDeleteWorkspace = () => {
    setShowDeleteWorkspace(!showDeleteWorkspace);
  };

  const loadWorkspace = async () => {
    await loadAccountProfile();
    await loadComposition(workspaceId);
    await loadWorkspaceAccounts(workspaceId);
    const currentUserId = AppState.accountId.value;
    const workspaceAccounts = AppState.workspaceAccounts.value;
    const currentUser = workspaceAccounts.find((user) => user.id === currentUserId);
    let actions = [];
    if (currentUser.isAdmin) {
      actions = [
        { action: onToggleLeaveWorkspace, label: 'Leave Workspace' },
        { action: onToggleDeleteWorkspace, label: 'Delete Workspace', isDestructive: true },
      ];
    } else {
      actions = [
        { action: onToggleLeaveWorkspace, label: 'Leave Workspace' },
      ];
    }
    setMenuActions(actions);
    setActiveUser(currentUser);
    setShowJoinWorkspace(!currentUser.hasJoined);
  };

  const handlePollForData = () => {
    loadWorkspaceAccounts(workspaceId);
  };

  usePollForData(true, 5000, handlePollForData, false);

  useEffect(() => {
    document.title = 'Composition';
    AppState.workspaceInvitesToSend.value = [];

    scrollIntoView(document.querySelector('#scrollPoint'), { behavior: 'instant', block: 'center', inline: 'center' });

    loadWorkspace().then(() => {
      setIsLoadingWorkspace(false);
    });
  }, []);

  const handleTabChange = (tab) => {
    setWorkspaceTab(tab.value);
  };

  const onCloseWorkspace = () => {
    if (AppState.navigation.backActionPath.value != null) {
      route(AppState.navigation.backActionPath.value);
    } else {
      route('/compositions');
    }
  };

  const onToggleShareWorkspace = () => {
    setShowShareWorkspace(!showShareWorkspace);
  };

  const onClickJoinWorkspace = () => {
    setIsJoining(true);
    setJoinWorkspaceError(null);

    postWorkspaceConfirm(
      workspaceId,
      null,
      workspacePersona ? parseInt(workspacePersona, 10) : null,
      (activeUser.suggestions?.ipi && activeUser.suggestions.ipi !== AppState.artistProfile.ipi.value && acceptSuggestedIPI)
        ? activeUser.suggestions.ipi
        : null,
      (activeUser.suggestions?.society && activeUser.suggestions.society.name !== AppState.artistProfile.swCollSociety.value[0]?.name && acceptSuggestedSociety)
        ? activeUser.suggestions.society
        : null,
    )
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          setIsLoadingWorkspace(true);
          setTimeout(() => {
            loadWorkspace().then(() => {
              setIsJoining(false);
              setJoinWorkspaceError(null);
              setIsLoadingWorkspace(false);
            });
          }, 1200);
        } else {
          response.json().then((json) => {
            setJoinWorkspaceError(json.error || response.statusText);
          });
        }
      })
      .catch((err) => {
        setIsJoining(true);
        setJoinWorkspaceError(err.message);
      });
  };

  const onClickCancelJoinWorkspace = () => {
    route('/home');
  };

  return (
    showJoinWorkspace ? (
      <WorkspaceEditOverlay
        formWidth="23em"
        formCentered
      >
        <Header mb="0.25em">
          Join:
          {' '}
          {AppState.composition.name.value}
        </Header>
        <Text variant="thinPrompt" mb="1.5em">By clicking Continue, you confirm that you are a participant.</Text>

        {AppState.aliases.value.length > 0 && (
        <Fragment>
          <InputLabel label="Use a Persona (Optional)" />
          <BaseSelect
            onChange={(e) => setWorkspacePersona(e.target.value)}
            id="persona"
            name="persona"
            fluid
            mb="1.5rem"
          >
            <option value="" default selected>
              None (
              {AppState.userProfile.firstName.value}
              {' '}
              {AppState.userProfile.lastName.value}
              )
            </option>
            {AppState.aliases.value.map((a) => (
              <option value={a.id}>{a.name}</option>
            ))}
          </BaseSelect>
        </Fragment>
        )}

        {(activeUser.suggestions?.society?.name && activeUser.suggestions.society.name !== AppState.artistProfile.swCollSociety.value[0]?.name) && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested Collection Society</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedSociety ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {activeUser.suggestions.society.name}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="society">
              <input
                type="checkbox"
                name="society"
                id="society"
                onChange={() => setAcceptSuggestedSociety(!acceptSuggestedSociety)}
                checked={acceptSuggestedSociety}
              />
            </ToggleSwitch>
          </Box>
        </Box>
        )}

        {(activeUser.suggestions?.ipi && activeUser.suggestions.ipi !== AppState.artistProfile.ipi.value) && (
        <Box>
          <BreakLine mb="1.5em" color="var(--panel-info-border)" />
          <Text fontWeight="800">Suggested IPI Number</Text>
          <Text fontSize="1.67em" mb="0.125em" fontWeight="200" color={acceptSuggestedIPI ? 'var(--text-soft-mute)' : 'var(--text-medium-mute)'}>
            {activeUser.suggestions.ipi}
          </Text>
          <Box display="flex" justifyContent="flex-end" mb="1.5em">
            <Text mr="1.25em" fontSize="0.75rem" color="var(--text-primary)">Add to Profile</Text>
            <ToggleSwitch id="ipi">
              <input
                type="checkbox"
                name="ipi"
                id="ipi"
                onChange={() => setAcceptSuggestedIPI(!acceptSuggestedIPI)}
                checked={acceptSuggestedIPI}
              />
            </ToggleSwitch>
          </Box>
        </Box>
        )}

        <Box mt="2px">
          {joinWorkspaceError && (
          <AlertMessage
            variant="negative"
            message={joinWorkspaceError}
            mb="1.5em"
          />
          )}

          <Box>
            <BaseButton
              mb="1.5em"
              btnText="Join Composition"
              onClick={onClickJoinWorkspace}
              disabled={isJoining}
              isLoading={isJoining}
              fluid
            />
            <BaseButton
              mb="1.5em"
              btnText="Cancel"
              variant="secondary"
              onClick={onClickCancelJoinWorkspace}
              disabled={isJoining}
              fluid
            />
          </Box>
        </Box>
      </WorkspaceEditOverlay>
    ) : (
      <WorkspaceWrapper isEditing={isChildEditing}>
        <HeaderWrapper id="scrollPoint">
          <MobileHeaderContent>
            <Box justifySelf="flexStart" flex="0">
              <UtilityButton iconSize="1rem" icon="arrow-left" onClick={onCloseWorkspace} iconTransform="translateY(-1px)" />
            </Box>
            <MobileTitleWrapper>
              <Text fontWeight="700" fontSize="0.938rem" color="var(--text-primary)">Composition</Text>
            </MobileTitleWrapper>
            {!isLoadingWorkspace && (
            <Box display="flex" justifySelf="flex-end" justifyContent="flex-end" position="relative">
              <UtilityButton iconSize="1rem" mr="1rem" icon="share" onClick={onToggleShareWorkspace} />
              <UtilityButton
                iconSize="1rem"
                icon="menu"
                iconTransform="translateY(-4px)"
                menuOptions={menuActions}
              />
            </Box>
            )}
          </MobileHeaderContent>
          <HeaderContent>
            <HeaderDetails className="cursor-p" onClick={onCloseWorkspace}>
              <Icon pt="0.188em" cursor><SoloLogo /></Icon>
              <Text fontWeight="700" ml="0.75em" fontSize="1em" color="var(--text-primary)">Composition</Text>
            </HeaderDetails>
            <HeaderActions>
              <UtilityButton
                mr="0.875rem"
                suffixDecoration="share"
                suffixIconSize="0.938rem"
                suffixIconTransform="translateY(2px)"
                btnText="Share"
                onClick={onToggleShareWorkspace}
              />
              <UtilityButton
                btnText="Actions"
                suffixDecoration="chevron"
                menuOptions={menuActions}
              />
              <UtilityButton ml="0.875em" icon="close" onClick={onCloseWorkspace} />
            </HeaderActions>
          </HeaderContent>
        </HeaderWrapper>
        {isLoadingWorkspace ? (
          <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
            <Spinner size="2em" variant="page" />
          </Box>
        ) : (
          <PageWrapper>
            <PageContent>
              <NavigationWrapper>
                <NavigationTabs>
                  <Tabs
                    tabs={[{ name: 'Ownership', value: 'Main' }, { name: 'Lyrics & Files', value: 'Proof' }, { name: 'Samples', value: 'OtherData' }, { name: 'Activity', value: 'Activity' }]}
                    handleTabChange={handleTabChange}
                    selectedTab={workspaceTab}
                  />
                </NavigationTabs>
              </NavigationWrapper>
              <WorkItemsWrapper style={AppState.media.currentlyPlaying.value && { marginBottom: '6.5em' }}>
                <Box width="100%">
                  {{
                    Main:
                      (
                        <Box pb="1.5em">
                          <CompositionDetailsSection workspaceId={workspaceId} activeUser={activeUser} />
                          <CompositionOwnershipActions workspaceId={workspaceId} activeUser={activeUser} />
                          <CompositionWriterShareSection workspaceId={workspaceId} activeUser={activeUser} />
                        </Box>
                      ),
                    Proof:
                      (
                        <ProofWrapper>
                          <Box>
                            <WorkspaceRecordingSection workspaceId={workspaceId} activeUser={activeUser} />
                            <WorkspaceFileSection workspaceId={workspaceId} activeUser={activeUser} />
                          </Box>
                          <StructuredLyrics workspaceId={workspaceId} activeUser={activeUser} />
                        </ProofWrapper>
                      ),
                    OtherData: <CompositionInterpolationSection workspaceId={workspaceId} activeUser={activeUser} />,
                    Activity: <ActivitiesSection workspace={workspaceId} />,
                  }[workspaceTab]}

                </Box>
              </WorkItemsWrapper>
            </PageContent>
          </PageWrapper>
        )}

        {showLeaveWorkspace
        && <LeaveWorkspace closeFunction={onToggleLeaveWorkspace} activeUser={activeUser} />}
        {showDeleteWorkspace
        && <DeleteWorkspace closeFunction={onToggleDeleteWorkspace} />}
        {showShareWorkspace
        && <ShareWorkspace closeFunction={onToggleShareWorkspace} />}
      </WorkspaceWrapper>
    )

  );
}

CompositionWorkspace.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CompositionWorkspace;
