import { deepSignal } from '@deepsignal/preact';

const AppState = deepSignal({
  timestamp: 0,
  accountId: 0,
  repSession: {
    isActive: false,
    repName: '',
    repTasks: [],
    powerOfAttorney: false,
  },
  workspace: {
    isEditingChild: false,
    writerToManage: null,
    guestToManage: null,
    writerSignatureData: null,
  },
  writer: false,
  subscribed: false,
  verified: false,
  paid: false,
  verifyError: null,
  workspaceAccounts: [],
  workspaceInvites: [],
  workspaceInvitesToSend: [],
  aliases: [],
  userProfile: {
    email: '',
    phone: '',
    legalName: '',
    firstName: '',
    lastName: '',
    country: '',
    username: '',
    imgURL: null,
    verifiedData: null,
  },
  artistProfile: {
    uuid: '',
    did: '',
    swCollSociety: [],
    perfCollSociety: null,
    ipi: null,
    ipn: '',
    isni: '',
    ipiCritical: false,
    societyCritical: false,
    publisherCritical: false,
    criticalChecksComplete: false,
    isEditingChild: false,
    isEditingCurrentPreset: false,
  },
  profileLoaded: false,
  publishers: null,
  publisherPresets: [],
  compositions: [],
  composition: {
    id: 0,
    version: 0,
    uuid: '',
    did: '',
    name: '',
    tags: [],
    alternateTitles: [],
    date: '',
    intendedUse: '',
    hash: '',
    isLocked: false,
    shareableVersion: 0,
    hasSamples: false,
    samples: [],
  },
  contracts: [],
  artistRecordings: {
    isPreviewActive: false,
  },
  isRepresentative: false,
  repProfile: {
    subscription: null,
    maxArtistsReached: false,
  },
  organizations: [],
  pubOrganization: {
    id: 0,
    uuid: '',
    name: '',
    logoURL: null,
    customTags: [],
    customStatuses: [],
    receiveSummaryEmail: false,
    automaticRegistrationHold: false,
  },
  orgRegistrations: [],
  orgConnections: [],
  exceptions: {
    errorPageError: null,
    errorOverlay: {
      display: false,
      errorTitle: null,
      userMessage: null,
      errorMessage: null,
      buttonText: null,
      buttonPath: null,
      bypassAuthCheck: false,
    },
  },
  navigation: {
    backActionPath: null,
    defaultTabListener: 0,
  },
  publisherLists: [],
  publisherActiveSongwriters: [],
  publisherEditingCredential: {
    id: 0,
    recipient: null,
    terms: null,
    requirements: null,
  },
  publisherCompositionsTable: {
    headerSearchListener: 0,
    activeConfig: {
      isAscending: false,
      sort: 'created',
      toggle: 'all',
      query: '',
      filters: {
        status: [],
        internalTags: [],
        compositionTags: [],
        connectedPublishers: [],
      },
    },
    currentPage: 1,
    itemsPerPage: 10,
  },
  pagination: {
    artistComposition: 1,
    artistRecording: 1,
    artistContract: 1,
    organizationTeam: 1,
    organizationSongwriter: 1,
  },
  media: {
    currentlyPlaying: null,
    currentlyPlayingUUID: null,
    isPlaying: false,
    currentTime: null,
    workspaceElementSeekTime: null,
    artworkURL: null,
    compositionName: null,
    isCompositionFileMaster: false,
    recordingName: null,
    recordingProjectName: null,
    recordingProjectArtistName: null,
  },
  messages: {
    toastMessage: null,
  },
  deepLinkUrl: '',
  isMobile: false,
});

export default AppState;
