import PropTypes from 'prop-types';

import Card from '@distinct-components/cards/baseCard';
import UtilityButton from '@ui-kit/buttons/utilityButton';

import {
  CardHeaderWrapper,
  CardHeader,
  CardBodyWrapper,
} from './HeaderCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function HeaderCard({
  children,
  headerText,
  editFunction,
  ...restProps
}) {
  return (
    <Card padding="0em" {...restProps} className="sc-card-mobile-bg">
      <CardHeaderWrapper>
        <CardHeader>
          {headerText}
        </CardHeader>
        {editFunction && (
          <UtilityButton style={{ transform: 'translateX(8px)' }} ml="0.875em" icon="edit" onClick={editFunction} />
        )}
      </CardHeaderWrapper>
      <CardBodyWrapper>
        {children}
      </CardBodyWrapper>
    </Card>
  );
}

HeaderCard.propTypes = {
  children: childrenPropTypes.isRequired,
  headerText: PropTypes.string.isRequired,
  editFunction: PropTypes.func,
};

HeaderCard.defaultProps = {
  editFunction: null,
};

export default HeaderCard;
