/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state';

import { postOrgCompositionComment, deleteOrgCompositionComment } from '@api/restricted/org-composition-comments-api';

import { useTimeAgo } from '@hooks/useTimeAgo';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import Avatar from '@ui-kit/avatar';
import BaseButton from '@ui-kit/buttons/baseButton';
import Icon from '@ui-kit/icon';
import TrashIcon from '@assets/icons/trash-outline.svg';

import {
  StyledCommentCard,
  StyledTextArea,
  DeleteCommentWrapper,
} from './ToolsCommentsStyles';

function ToolsComments({ compositionId, loadInternalData, comments }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [newComment, setNewComment] = useState();

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const onClickSaveComment = () => {
    if (compositionId < 1) {
      // VAPOR
      alert(`save the user comment: ${newComment}`);
      setNewComment('');
      return;
    }

    setIsLoading(true);
    postOrgCompositionComment(AppState.pubOrganization.id.value, compositionId, newComment)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            // Might want to have success toast alert
            setIsLoading(false);
            setNewComment('');
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        useToastAlertError(err.message);
      });
  };

  const onClickDeleteComment = (commentId) => {
    if (isDeleting) {
      return;
    }
    if (compositionId < 1) {
      // VAPOR
      alert(`comment deleted: ${commentId}`);
      return;
    }

    setIsDeleting(true);
    deleteOrgCompositionComment(AppState.pubOrganization.id.value, commentId)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            // Might want to have success toast alert
            setIsDeleting(false);
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsDeleting(false);
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        useToastAlertError(err.message);
      });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <Box p="1em 1em 0em 1em">
      <InputLabel mb="1em" label="Team Comments" />
      <StyledTextArea
        mb="0.75em"
        fluid
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        onKeyDown={onKeyDown}
        isActive={newComment?.length > 0}
        placeholder="Add Your Comment..."
      />
      {newComment?.length > 0 && (
      <BaseButton
        fluid
        mb="1em"
        maxHeight="2em"
        btnText="Save"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={onClickSaveComment}
        onMouseDown={(e) => e.preventDefault()}
      />
      )}
      {comments.length > 0 ? (
        <Box>
          {comments.map((comment) => (
            <StyledCommentCard>
              {comment.accountId === AppState.accountId.value
              && (
              <DeleteCommentWrapper isDeleting={isDeleting} onClick={() => onClickDeleteComment(comment.id)}>
                <Icon size="0.938em" className="cursor-inherit"><TrashIcon /></Icon>
              </DeleteCommentWrapper>
              )}
              <Box display="flex" alignItems="center" mb="0.75em">
                <Avatar size="2em" imgURL={comment.avatarURL} />
                <Box ml="0.75em">
                  <Text lineHeight="1" fontWeight="600" mb="0.25em" fontSize="0.875em">
                    {comment.accountId === AppState.accountId.value ? 'You' : comment.name}
                  </Text>
                  <Text lineHeight="1" fontSize="0.75em" color="var(--text-medium-mute)">
                    {useTimeAgo(comment.createdAt)}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text fontWeight="400" fontSize="0.75rem">
                  &apos;
                  {comment.message}
                  &apos;
                </Text>
              </Box>
            </StyledCommentCard>
          ))}
        </Box>
      ) : (
        <Text fontSize="0.75rem" mb="1rem" color="var(--text-secondary)">No Comments to Display</Text>
      )}
    </Box>
  );
}

ToolsComments.propTypes = {
  compositionId: PropTypes.number.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  comments: PropTypes.array.isRequired,
};

export default ToolsComments;
