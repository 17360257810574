import PropTypes from 'prop-types';

import HeaderCard from '@distinct-components/cards/headerCard';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Box from '@ui-kit/box';

function ViewCompositionIdentifiers({ composition, handleEditIdentifiers }) {
  const iswc = composition?.iswc;
  const ccli = composition?.miscData?.ccli;
  return (
    <HeaderCard mb="1.5em" headerText="Identifiers" editFunction={handleEditIdentifiers}>
      <Box mt="0.25rem">

        <Text mb="0.25rem" fontSize="0.813rem" color={iswc ? 'var(--text-primary)' : 'var(--text-secondary)'} fontWeight="400">
          <Span fontWeight="500">ISWC:</Span>
          {' '}
          {composition?.iswc || 'Not Defined'}
        </Text>

        {ccli && (
        <Text mb="0.25rem" fontSize="0.813rem" color="var(--text-primary)" fontWeight="400">
          CCLI:
          {' '}
          {ccli || 'Not Defined'}
        </Text>
        )}

      </Box>
    </HeaderCard>
  );
}

ViewCompositionIdentifiers.propTypes = {
  composition: PropTypes.object.isRequired,
  handleEditIdentifiers: PropTypes.func.isRequired,
};

export default ViewCompositionIdentifiers;
