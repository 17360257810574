/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import AppState from '@state';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import AlertMessage from '@ui-kit/alert/Alert';

import PublisherTerritories from '@constants/publisherTerritories';

import useRoundNumber from '@hooks/useRoundNumber';

import {
  StyledPercentBox,
  StyledPercentageInput,
  StyledDateWrapper,
} from './AddWriterTermsStyles';

const d = dayjs().format();
const TODAY_DATE = d.substring(0, 10);

function AddWriterTerms({
  terms,
  setTerms,
  setStep,
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [hasEndDate, setHasEndDate] = useState(true);
  const [hasOwnership, setHasOwnership] = useState(true);

  const {
    register, handleSubmit, setValue, watch, formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ownershipShare: terms?.ownershipShare || 100,
      fromDate: TODAY_DATE,
    },
  });

  const controlled = watch('compositionsControlled');

  useEffect(() => {
    if (terms) {
      setValue('compositionsControlled', terms.compositionsControlled);
      setHasEndDate(terms.hasEndDate);
      setValue('fromDate', terms.fromDate?.substring(0, 10) || TODAY_DATE);
      setValue('toDate', terms.toDate?.substring(0, 10) || null);
      setValue('publisherRegistrationId', terms.publisherRegistrationId);
      setHasOwnership(terms.hasOwnership);
      setValue('territory', terms.territory);
    }
    setIsLoading(false);
  }, []);

  const onToggleOwnership = () => {
    setHasOwnership(!hasOwnership);
  };

  const onClickContinue = (data) => {
    const utcFromDate = data.fromDate ? dayjs(data.fromDate) : null;
    const utcToDate = data.toDate ? dayjs(data.toDate).endOf('day') : null;
    if (data.compositionsControlled === 'all') {
      if (!utcFromDate) {
        setError('Enter a start date');
        return;
      }
      if (hasEndDate) {
        if (!utcToDate) {
          setError('Enter an end date or set "Until Revoked"');
          return;
        }
        if (utcFromDate.isAfter(utcToDate)) {
          setError('Enter a valid time frame');
          return;
        }
      }
    }

    const formattedShare = data.ownershipShare ? useRoundNumber(data.ownershipShare, 2) : 0;
    if (hasOwnership && (formattedShare < 0.01 || formattedShare > 100)) {
      setError('Enter a valid ownership interest');
      return;
    }

    setError('');
    setIsSaving(true);
    const termSet = {
      compositionsControlled: data.compositionsControlled,
      hasEndDate,
      fromDate: utcFromDate?.format() || null,
      toDate: utcToDate?.format() || null,
      publisherRegistrationId: data.publisherRegistrationId,
      hasOwnership,
      ownershipShare: formattedShare,
      territory: data.territory,
    };
    setTerms(termSet);
    setTimeout(() => {
      setIsSaving(false);
      if (!AppState.publisherEditingCredential.id.value && (!hasOwnership || termSet.ownershipShare < 100)) {
        setStep('copub');
      } else {
        setStep('commitment');
      }
    }, 800);
  };

  return (
    <Box {...restProps}>
      <BreakLine mb="1.5em" mt="0.5em" color="var(--panel-info-border)" />

      <form id="termsForm" onSubmit={handleSubmit(onClickContinue)} autoComplete="off">

        <InputLabel label="What Works does this apply to?" />
        <BaseSelect
          {...register('compositionsControlled', { required: true })}
          id="compositionsControlled"
          name="compositionsControlled"
          fluid
          mb="0.5em"
        >
          <option value="all" default selected>All Works Created by Writer</option>
          <option value="some">Only Select Works</option>
        </BaseSelect>
        {controlled === 'all'
          ? <InputLabel mb="1.75em" color="var(--text-medium-mute)" label="Forces use of this info on every composition" />
          : <InputLabel mb="1.75em" color="var(--text-medium-mute)" label="Writer can manually apply this to compositions" />}

        {controlled === 'all'
        && (
        <Fragment>
          <InputLabel label="During what Time Frame?" />
          <Box display="flex" mb="1.5em" width="100%">
            <ButtonSegment onClick={() => setHasEndDate(true)} btnText="Until a Date" variant="first" active={hasEndDate} fluid maxHeight="inherit" />
            <ButtonSegment onClick={() => setHasEndDate(false)} btnText="Until Revoked" variant="last" active={!hasEndDate} fluid maxHeight="inherit" />
          </Box>

          <StyledDateWrapper>
            <Box className="dateBox" width={hasEndDate ? '18em' : '12em'}>
              <InputLabel label="From Date" />
              <BaseInput
                {...register('fromDate', { required: true })}
                id="fromDate"
                name="fromDate"
                type="date"
                max={TODAY_DATE}
                fluid
                mb="2.5em"
                required
              />
            </Box>
            {hasEndDate && (
            <Fragment>
              <BreakLine m="0em 1em 1em" width="10%" color="var(--panel-info-border)" />
              <Box className="dateBox" width="18em">
                <InputLabel label="To Date" />
                <BaseInput
                  {...register('toDate', { required: false })}
                  id="toDate"
                  name="toDate"
                  type="date"
                  min={TODAY_DATE}
                  fluid
                  mb="2.5em"
                  required
                />
              </Box>
            </Fragment>
            )}
          </StyledDateWrapper>
        </Fragment>
        )}

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />

        <InputLabel label="PRO Affiliation" />
        <BaseSelect
          {...register('publisherRegistrationId', { required: true, valueAsNumber: true })}
          id="publisherRegistrationId"
          name="publisherRegistrationId"
          fluid
          mb="1.5rem"
        >
          <option value="" default selected>Select one</option>
          {AppState.orgRegistrations.value.map((reg) => (
            <option value={reg.id}>
              {reg.name}
              {' '}
              -
              {' '}
              {reg.society}
            </option>
          ))}
        </BaseSelect>

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />

        <Box display="flex" justifyContent="space-between" mb="1.25em">
          <Text fontWeight="600">Ownership Interest</Text>
          <ToggleSwitch id="ownership">
            <input
              id="ownership"
              name="ownership"
              type="checkbox"
              disabled={isLoading}
              checked={hasOwnership}
              onChange={onToggleOwnership}
            />
          </ToggleSwitch>
        </Box>

        {hasOwnership
        && (
        <Box mb="1.5em">
          <InputLabel label="Undivided Ownership Interest" />
          <Box display="flex" mb="0.25em">
            <StyledPercentageInput
              {...register('ownershipShare', { required: false, valueAsNumber: true })}
              id="ownershipShare"
              name="ownershipShare"
              placeholder={0.00}
              type="number"
              inputMode="decimal"
              step="0.01"
              max={100}
              min={0.01}
              required // this means not required if toggle is off, otherwise required
            />
            <StyledPercentBox>%</StyledPercentBox>
          </Box>
        </Box>
        )}

        <BreakLine mb="1.5em" color="var(--panel-info-border)" />

        <InputLabel label="Administration Territory" />
        <BaseSelect
          {...register('territory', { required: true })}
          id="territory"
          name="territory"
          fluid
          mb="1.5rem"
        >
          <option value="Worldwide" default selected>Worldwide</option>
          {PublisherTerritories.map((t) => (
            <option value={t}>{t}</option>
          ))}
        </BaseSelect>

        {error
        && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

        <BreakLine mb="1.5em" color="var(--border)" />
        <Box display="flex" justifyContent="flex-end">
          <BaseButton
            mb="1.5em"
            btnText="Continue"
            disabled={isSaving || !isValid}
            isLoading={isSaving}
            fluid
            decoration="arrow"
          />
        </Box>
      </form>

    </Box>
  );
}

AddWriterTerms.propTypes = {
  setStep: PropTypes.func.isRequired,
  setTerms: PropTypes.func.isRequired,
  terms: PropTypes.object,
};

AddWriterTerms.defaultProps = {
  terms: null,
};

export default AddWriterTerms;
