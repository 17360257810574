/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import {
  postWorkspaceRecording,
  deleteWorkspaceRecording,
  getWorkspaceRecordingFile,
} from '@api/restricted/workspace-recording-api';
import getWorkspaceRecordings from '@api/restricted/workspace-recordings-api';

import useFileSize from '@hooks/useFileSize';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import WorkspaceCard from '@distinct-components/cards/workspaceCard';
import DragAndDrop from '@distinct-components/files/dragAndDrop';
import FileCard from '@distinct-components/files/fileCard/FileCard';
import Spinner from '@ui-kit/loaders/Spinner';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import Tooltip from '@distinct-components/feedback/tooltip';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import ActionButtonPair from '@distinct-components/buttonGroups/actionButtonPair';
import FileArtworkImage from '@distinct-components/files/fileArtworkImage/FileArtworkImage';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import { FormInputWrapper } from './WorkspaceRecordingSectionStyles';

function WorkspaceRecordingSection({ workspaceId, activeUser, publisherEditComplete }) {
  const [recordingError, setRecordingError] = useState(null);
  const [isProcessingRecordings, setIsProcessingRecordings] = useState(false);
  const [showFileForm, setShowFileForm] = useState(false);
  const [fileToAdd, setFileToAdd] = useState(null);
  const [fileType, setFileType] = useState('Demo');
  const [isFilePublic, setIsFilePublic] = useState(true);
  const [fileDisplayName, setFileDisplayName] = useState(null);
  const [addedRecordings, setAddedRecordings] = useState([]);

  const loadWorkspaceRecordings = async (id) => {
    try {
      const response = await getWorkspaceRecordings(id);
      const json = await response.json();
      if (response.status !== 200) {
        setRecordingError(json.error || response.statusText);
      } else {
        setAddedRecordings(json.recordings);
      }
    } catch (err) {
      setRecordingError(err.message);
    }
  };

  useEffect(() => {
    if (!publisherEditComplete) {
      loadWorkspaceRecordings(workspaceId);
    }
  }, []);

  const handleFilesAdded = (files) => {
    if (files.length > 1) {
      setRecordingError('Add Recordings One at a Time');
    } else {
      const file = files[0];
      const fileName = file.name;
      const re = /(\.wav|\.mp3|\.m4a)$/;

      if (!re.exec(fileName.toLowerCase())) {
        setRecordingError('Supported Audio: .mp3 .m4a .wav');
      } else if (!file.type.startsWith('audio')) {
        setRecordingError('File type not supported');
      } else if (file.size >= 20000000) {
        setRecordingError('Recording must be less than 20 MB');
      } else if (addedRecordings.length + files.length >= 5) {
        setRecordingError('You can only have a maximum of 5 recordings');
      } else {
        setFileToAdd(file);
        setFileDisplayName(file.name);
        setShowFileForm(true);
      }
    }
  };

  const handleResetFileForm = () => {
    setFileToAdd(null);
    setShowFileForm(false);
    setFileType('Demo');
    setIsFilePublic(true);
    setFileDisplayName(null);
  };

  const onClickAddRecording = () => {
    setRecordingError(null);
    setIsProcessingRecordings(true);
    handleResetFileForm();

    const cleanName = (name) => name.trim().split(/\s+/).join(' ');

    postWorkspaceRecording(
      publisherEditComplete ? AppState.pubOrganization.id.value : null,
      workspaceId,
      cleanName(fileDisplayName) || fileToAdd.name,
      fileToAdd.name,
      fileToAdd.type,
      fileToAdd.size,
      fileType,
      !isFilePublic,
      fileToAdd,
    ).then((response) => {
      if (response.status === 200) {
        setRecordingError(null);
        if (!publisherEditComplete) {
          loadWorkspaceRecordings(workspaceId).then(() => {
            setIsProcessingRecordings(false);
          });
        } else {
          publisherEditComplete();
        }
      } else {
        response.json()
          .then((json) => setRecordingError(json.error || response.statusText))
          .catch(() => setRecordingError(response.statusText));
        setIsProcessingRecordings(false);
      }
    }).catch((err) => {
      setRecordingError(err.message);
      setIsProcessingRecordings(false);
    });
  };

  const onClickCancelRecording = () => {
    handleResetFileForm();
  };

  const handleRemoveRecording = (recording) => {
    deleteWorkspaceRecording(null, workspaceId, recording.id)
      .then((response) => {
        if (response.status === 200) {
          loadWorkspaceRecordings(workspaceId).then(() => {
            setRecordingError('');
          });
        } else {
          response.json().then((json) => {
            setRecordingError(json.error || response.statusText);
          });
        }
      })
      .catch((err) => {
        setRecordingError(err.message);
      });
  };

  const handleFileDownload = (file) => {
    getWorkspaceRecordingFile(AppState.composition.uuid.value, file.id)
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        } else {
          response.json().then((json) => {
            setRecordingError(json.error || response.statusText);
          });
        }
      })
      .catch((err) => {
        setRecordingError(err.message);
      });
  };

  const handlePlayRecording = (rec) => {
    if (AppState.media.currentlyPlaying.value === rec && AppState.media.isPlaying.value === true) {
      AppState.media.currentlyPlaying.value = null;
      AppState.media.currentlyPlayingUUID.value = null;
      AppState.media.isPlaying.value = false;
      AppState.media.isCompositionFileMaster.value = false;
      AppState.media.compositionName.value = null;
    } else {
      AppState.media.compositionName.value = AppState.composition.name.value;
      AppState.media.currentlyPlayingUUID.value = AppState.composition.uuid.value;
      AppState.media.currentlyPlaying.value = rec;
      if (rec.recordingType === 'Master') { AppState.media.isCompositionFileMaster.value = true; }
    }
  };

  return (
    <Box display="flex" mb="1.5em">
      <WorkspaceCard
        title="Demos & Recordings"
        isCardLocked={!activeUser.isCanEdit}
        lockedText={COMPOSITION_EXPLANATIONS.locks.manageSectionBlocked}
        minHeight="20em"
      >
        {activeUser.isCanEdit && (
        <Fragment>
          {!showFileForm ? (
            <Fragment>
              <Text mb="1em" fontSize="0.813rem">Add Demos or Recordings</Text>
              <DragAndDrop
                handleFilesAdded={handleFilesAdded}
                disabled={isProcessingRecordings}
                id="recording-drop"
              />
            </Fragment>
          ) : (
            <Box mt="0.5rem">
              <FormInputWrapper>

                <Box id="name">
                  <InputLabel label="File Display Name" />
                  <BaseInput
                    id="displayName"
                    name="displayName"
                    onChange={(e) => setFileDisplayName(e.target.value)}
                    value={fileDisplayName}
                    fluid
                    type="text"
                    autoComplete="off"
                    mb="1.25rem"
                  />
                </Box>
                <Box id="type">
                  <InputLabel label="Recording Type" />
                  <BaseSelect
                    onChange={(e) => setFileType(e.target.value)}
                    value={fileType}
                    fluid
                    mb="1.25rem"
                  >
                    <option value="Demo" default selected>Demo</option>
                    <option value="Master Recording">Master Recording</option>
                  </BaseSelect>
                </Box>

              </FormInputWrapper>

              <Box display="flex" mb="2em" justifyContent="flex-end">
                <Box display="flex" alignItems="center">
                  <Text fontSize="0.75rem" color="var(--text-primary)" mr="0.5rem">Show on Public Page</Text>
                  <Tooltip prompt="When finalized, this composition will have a public page you can share. Controls if this recording should be shown on the public page." />
                </Box>
                <ToggleSwitch id="isPublic" ml="0.75rem">
                  <input
                    type="checkbox"
                    name="isPublic"
                    id="isPublic"
                    checked={isFilePublic}
                    onChange={() => setIsFilePublic(!isFilePublic)}
                  />
                </ToggleSwitch>
              </Box>

              <ActionButtonPair
                primaryText="Add File"
                primaryFunction={() => onClickAddRecording()}
                secondaryText="Cancel"
                secondaryFunction={onClickCancelRecording}
                mb="2.5rem"
              />
            </Box>
          )}

          {recordingError && (
          <AlertMessage
            variant="negative"
            message={recordingError}
            mb="1.5em"
            mt="1.5em"
            dismissAlert={() => setRecordingError(false)}
          />
          )}

        </Fragment>
        )}
        {isProcessingRecordings && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="4em">
            <Spinner size="1.25em" variant="page" />
          </Box>
        )}

        {(!publisherEditComplete && addedRecordings.length > 0) && (
          <Box id="box" mt="1em">
            <Text fontSize="0.75rem" mb="0.75em">
              Recordings Added:
              {' '}
              {addedRecordings.length}
            </Text>
            {addedRecordings.sort((a, b) => b.id - a.id).map((recording) => (
              <FileCard
                file={recording}
                primaryDetail={useFileSize(recording.size)}
                handleFileDelete={handleRemoveRecording}
                handleFileDownload={handleFileDownload}
                activeUser={activeUser}
              >
                <FileArtworkImage
                  fileId={recording.id}
                  handleFilePlay={() => handlePlayRecording(recording)}
                  artworkURL={null}
                  isFileAvailable
                  toggleAction="stop"
                  mr="0.875rem"
                />

              </FileCard>
            ))}
          </Box>
        )}

      </WorkspaceCard>
    </Box>
  );
}

WorkspaceRecordingSection.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
  publisherEditComplete: PropTypes.func,
};

WorkspaceRecordingSection.defaultProps = {
  publisherEditComplete: null,
};

export default WorkspaceRecordingSection;
