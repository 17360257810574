import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import PropTypes from 'prop-types';
import AppState from '@state';

import { getOrgInactiveArtists } from '@api/restricted/org-artists-api';

import d from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Avatar from '@ui-kit/avatar';

import { TableSearchEmptyPrompt, TableIllustrationWrapper } from './ExpiredSongwriterStyles';

function ExpiredSongwriters({ setSelectedWriter }) {
  const [isLoadingWriters, setIsLoadingWriters] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [expiredWriters, setExpiredWriters] = useState([]);
  const [allExpiredWriters, setAllExpiredWriters] = useState([]);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.organizationSongwriter.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const loadInactiveWriters = async () => {
    try {
      const response = await getOrgInactiveArtists(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        const json = await response.json();
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading inactive writers`;
        route('/error/exception', true);
      } else {
        const json = await response.json();
        const formattedWriters = json.artists.map((w) => ({
          id: w.id,
          firstName: w.firstName,
          lastName: w.lastName,
          email: w.email,
          avatarURL: w.imgURL,
          expirationDate: w.expiry,
          totalCompositions: w.numComps,
          ipi: w.ipi,
          isni: w.isni,
          societies: w.societies,
          isVerified: w.isVerified,
        }));
        setAllExpiredWriters(formattedWriters);
        setExpiredWriters(formattedWriters);
        setSearchActive(false);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading inactive writers`;
      route('/error/exception', true);
    }
  };

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    setIsLoadingWriters(true);
    loadInactiveWriters().then(() => {
      setTimeout(() => {
        setIsLoadingWriters(false);
      }, 800);
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = expiredWriters.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      allExpiredWriters.forEach((w) => {
        const name = `${w.firstName} ${w.lastName}`;
        if (name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(w);
        }
      });
      setExpiredWriters(results);
      setSearchActive(true);
    } else {
      setExpiredWriters(allExpiredWriters);
      setSearchActive(false);
    }
  };

  return (
    isLoadingWriters ? (
      <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
        <Spinner size="2em" variant="page" />
      </Box>
    ) : (
      <Box>
        <div ref={pageRef} />
        {!searchActive && expiredWriters.length === 0
          ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="No Expired Writers"
                message="If a writer's credential expires, you can issue a new credential from this page"
                illustration="personas"
                minHeight="38em"
              />
            </Card>
          )
          : (
            <Fragment>
              <TableControls
                itemName="Songwriter"
                tableData={allExpiredWriters}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={expiredWriters}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="48%" pr="0.75em">Name</TableCell>
                  <TableCell>IPI</TableCell>
                  <TableCell>Total Works</TableCell>
                  <TableCell>Credential Expired</TableCell>
                </TableHeader>
                {currentResults
                  .map((writer) => (
                    <TableRow onClick={() => setSelectedWriter(writer)} cursor>
                      <TableCell widthPercent="48%" pr="0.75em">
                        <TableIllustrationWrapper>
                          <Box mr="1em">
                            <Avatar
                              size="3em"
                              imgURL={writer.avatarURL}
                              isVerified={writer.isVerified}
                            />
                          </Box>
                          <Box pt="0.25rem">
                            <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">
                              {writer.firstName}
                              {' '}
                              {writer.lastName}
                            </Text>
                            <Text mt="0.125em" fontSize="0.688rem" maxWidth="16em" color="var(--text-secondary)" truncate>
                              id:
                              {writer.id}
                            </Text>
                          </Box>
                        </TableIllustrationWrapper>
                      </TableCell>
                      <TableCell mobileLabel="IPI:" color={!writer.ipi && 'var(--text-medium-mute)'}>{writer.ipi || 'Not Defined'}</TableCell>
                      <TableCell mobileLabel="Works:">{writer.totalCompositions}</TableCell>
                      <TableCell mobileLabel="Expired:">{d(writer.expirationDate)}</TableCell>
                    </TableRow>
                  ))}
                {(searchActive && currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Invitations Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={expiredWriters.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

      </Box>
    )
  );
}

ExpiredSongwriters.propTypes = {
  setSelectedWriter: PropTypes.func.isRequired,
};

export default ExpiredSongwriters;
