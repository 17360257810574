/* eslint-disable import/prefer-default-export */
import { orgArtistInviteUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgArtistInvites(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgArtistInviteUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postOrgArtistInvite(regId, email, suggestions, claims, prevCredentialId) {
  const data = {
    reg: regId,
    email,
    suggestions,
    claims,
    prevCredential: prevCredentialId,
  };
  return refresh(async () => {
    const response = await fetch(orgArtistInviteUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchOrgArtistInviteResend(orgId, inviteId, email) {
  const data = {
    org: orgId,
    invite: inviteId,
    email,
  };
  return refresh(async () => {
    const response = await fetch(orgArtistInviteUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function deleteOrgArtistInvite(orgId, inviteId) {
  const data = {
    org: orgId,
    invite: inviteId,
  };
  return refresh(async () => {
    const response = await fetch(orgArtistInviteUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
