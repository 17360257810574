/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
import dayjs from 'dayjs';

import AppState from '@state';
import { getOrgArtist } from '@api/restricted/org-artist-api';

import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';
import d from '@hooks/useIsoDateFormat';
import useRoundNumber from '@hooks/useRoundNumber';
import useErrorOverlay from '@hooks/useErrorOverlay';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import Tabs from '@ui-kit/tabs/Tabs';
import LinkButton from '@ui-kit/buttons/linkButton';
import IdentifierDataRow from '@distinct-components/dataDisplay/identifierDataRow';

import ProfileOverlayStatsRow from './sections/profileOverlayStatsRow';
import ProfileOverlayCompositions from './sections/profileOverlayCompositions';
import ProfileOverlayPersonas from './sections/profileOverlayPersonas';
import ProfileOverlayCredentials from './sections/profileOverlayCredentials';

import {
  ModalContainer,
  ModalPage,
  ModalHeader,
  StyledCredentialBadge,
  StyledCloseButton,
  ProfileTabsSection,
} from './SongwriterProfileOverlayStyles';

function SongwriterProfileOverlay({
  closeFunction,
  writer,
  setWriterToNuke,
  setCredToRevoke,
  setCredToDelete,
}) {
  const [isLoadingWriter, setIsLoadingWriter] = useState(true);
  const [profileTab, setProfileTab] = useState('compositions');
  const [compositions, setCompositions] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [tags, setTags] = useState([]);
  const hasOnlyInactiveCredential = credentials.every((c) => !c.isActive);

  const ref = useRef();
  useClickOutside(ref, () => closeFunction());

  useKeyPressAction('Escape', () => {
    closeFunction();
  });

  const loadAnimation = anime.timeline({
    autoplay: false,
  });

  const loadWriterProfile = async () => {
    try {
      const response = await getOrgArtist(AppState.pubOrganization.id.value, writer.id);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading writer profile` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading writer profile` });
        }
      } else {
        const json = await response.json();

        const formattedComps = json.compositions.map((c) => ({
          id: c.id,
          did: c.uuid,
          uuid: c.uuid,
          compositionName: c.name,
          writtenDate: d(c.written),
          updatedAt: d(c.updated),
          writerCount: c.numWriters,
          finalized: c.finalized ? d(c.finalized) : null,
          status: c.status,
        }));
        setCompositions(formattedComps);

        const formattedCredentials = json.credentials.map((c) => ({
          id: c.id,
          regId: c.regId,
          acceptedDate: c.created_at,
          terms: {
            allWorks: c.data.credentialSubject.publishingDeal.allCompositions,
            fromDate: c.data.credentialSubject.publishingDeal.allCompositions ? c.data.issuanceDate : null,
            toDate: c.data.expirationDate,
            ownership: c.data.credentialSubject.publishingDeal.undividedOwnership,
            territory: c.data.credentialSubject.publishingDeal.adminTerritory,
          },
          commitment: c.commitment ? {
            ...c.commitment,
            progress: Math.min(100, useRoundNumber(((c.commitment.delivered.external + c.commitment.delivered.internal) * 100) / c.commitment.totalWorks, 2)),
          } : null,
          credentialJSON: c.data,
          isActive: !c.revoked_at && (!c.data.expirationDate || dayjs(c.data.expirationDate).isAfter(dayjs())),
          createdAt: c.created_at,
          issuedBy: c.data.issuer.name,
          issuedTo: `${writer.firstName} ${writer.lastName}`,
          featuredData: [
            { label: 'Associated Works', value: c.data.credentialSubject.publishingDeal.allCompositions ? 'All Works Written' : 'Only Some Works' },
            { label: 'From', value: c.data.credentialSubject.publishingDeal.allCompositions ? d(c.data.issuanceDate) : null },
            { label: 'Until', value: c.data.expirationDate ? d(c.data.expirationDate) : 'Credential Revoked' },
            { label: 'Ownership %', value: c.data.credentialSubject.publishingDeal.undividedOwnership ? `${c.data.credentialSubject.publishingDeal.undividedOwnership}%` : 'Admin Only' },
            { label: 'Territory', value: c.data.credentialSubject.publishingDeal.adminTerritory },
          ],
        }));

        setCredentials(formattedCredentials);

        setPersonas(json.personas);

        setTags(json.tags);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading writer profile` });
    }
  };

  useEffect(() => {
    loadWriterProfile().then(() => {
      loadAnimation.add({
        targets: '#overlay',
        backdropFilter: ['blur(0px)', 'blur(10px)'],
        duration: 2000,
      }, 100).add({
        targets: '#container',
        translateY: ['32px', 0],
        opacity: [0, 1],
        duration: 600,
        easing: 'easeOutQuad',
      }, 0);
      loadAnimation.play();
      setTimeout(() => {
        setIsLoadingWriter(false);
      }, 800);
    });
  }, []);

  const onProfileTabChange = (tab) => {
    setProfileTab(tab.value);
  };

  return (
    <ModalPage id="overlay">
      <Box ref={ref} m="0 auto" maxWidth="72em" minHeight="calc(100vh - 100px)">
        <ModalContainer id="container">
          <ModalHeader>

            <Box display="flex" alignItems="center">
              <Box mr="1.5em">
                <Avatar
                  size="4.25em"
                  imgURL={writer.avatarURL}
                  isVerified={writer.isVerified}
                  checkSize="1.25em"
                  checkY="5px"
                  checkX="-3px"
                />
              </Box>
              <Box>
                <Text fontSize="1.5rem" lineHeight="1" mb="0.5em" mt="0.25em" fontWeight="600" color="var(--text-primary)">
                  {writer.firstName}
                  {' '}
                  {writer.lastName}
                </Text>
                <IdentifierDataRow writer={writer} />
              </Box>
            </Box>

            <Box id="actions" display="flex" justifyContent="flex-end">
              <StyledCredentialBadge activeCredential={!hasOnlyInactiveCredential} onClick={() => setProfileTab('credentials')}>
                {!hasOnlyInactiveCredential ? 'Active Credential' : 'Inactive'}
              </StyledCredentialBadge>
              <StyledCloseButton onClick={closeFunction}>
                <Icon cursor size="0.75em"><CloseIcon /></Icon>
              </StyledCloseButton>
            </Box>
          </ModalHeader>

          <ProfileOverlayStatsRow numCompositions={compositions.length} credentials={credentials} tags={tags} />

          <ProfileTabsSection>
            <Tabs
              tabs={[{ name: 'Compositions', value: 'compositions' }, { name: 'Credentials', value: 'credentials' }, { name: 'Personas', value: 'personas' }]}
              handleTabChange={onProfileTabChange}
              selectedTab={profileTab}
            />
            {{
              compositions: <ProfileOverlayCompositions compositions={compositions} isLoading={isLoadingWriter} />,
              credentials: <ProfileOverlayCredentials credentials={credentials} writer={writer} isLoading={isLoadingWriter} setCredToRevoke={setCredToRevoke} setCredToDelete={setCredToDelete} writerName={`${writer.firstName} ${writer.lastName}`} />,
              personas: <ProfileOverlayPersonas personas={personas} isLoading={isLoadingWriter} />,
            }[profileTab]}
          </ProfileTabsSection>

        </ModalContainer>

        {setWriterToNuke && (
        <Box mt="1em" display="flex" justifyContent="center" alignItems="center" minHeight="3em">
          {hasOnlyInactiveCredential && <LinkButton btnText="Delete Writer & Compositions" color="var(--red-300)" onClick={() => setWriterToNuke(writer)} />}
        </Box>
        )}

      </Box>

    </ModalPage>
  );
}

SongwriterProfileOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  writer: PropTypes.object.isRequired,
  setWriterToNuke: PropTypes.func,
  setCredToRevoke: PropTypes.func.isRequired,
  setCredToDelete: PropTypes.func.isRequired,
};

SongwriterProfileOverlay.defaultProps = {
  setWriterToNuke: null,
};

export default SongwriterProfileOverlay;
