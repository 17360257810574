/* eslint-disable object-curly-newline */
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import PropTypes from 'prop-types';

import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import { deleteOrgArtist } from '@api/restricted/org-artists-api';
import { patchOrgArtistCredentialRevoked, deleteOrgArtistCredential } from '@api/restricted/org-artist-credentials-api';

import SongwriterProfileOverlay from '@shared-overlays/songwriterProfileOverlay';
import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';
// import Box from '@ui-kit/box';
// import Spinner from '@ui-kit/loaders/Spinner';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import { PUB_WRITER_EXPLANATIONS } from '@constants/supportingCopy';

import ActiveSongwriters from './sections/activeSongwriters';
import PendingSongwriters from './sections/pendingSongwriters';
import ExpiredSongwriters from './sections/expiredSongwriters';

const validModes = ['active', 'pending', 'inactive'];

function PublisherSongwriters({ uuid }) {
  const [writersMode, setWritersMode] = useState('active');
  const [selectedWriter, setSelectedWriter] = useState();
  const [writerToNuke, setWriterToNuke] = useState();
  const [isNukingWriter, setIsNukingWriter] = useState(false);
  const [isLoadingOrganization, setIsLoadingOrganization] = useState(true);
  const [error, setError] = useState();

  const [credToRevoke, setCredToRevoke] = useState(null);
  const [credToDelete, setCredToDelete] = useState(null);
  const [isRevoking, setIsRevoking] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    AppState.publisherEditingCredential.id.value = 0;
    AppState.publisherEditingCredential.recipient.value = null;
    AppState.publisherEditingCredential.terms.value = null;
    AppState.publisherEditingCredential.requirements.value = null;

    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('writers');
      if (token && validModes.includes(token)) {
        setWritersMode(token);
      } else {
        setWritersMode('active');
      }
      setIsLoadingOrganization(false);
    });
  }, [AppState.navigation.defaultTabListener.value]);

  const addNew = () => {
    route(`/publisher/${uuid}/songwriters/invite`);
  };

  const onClickActiveTab = () => {
    setWritersMode('active');
  };

  const onClickPendingTab = () => {
    setWritersMode('pending');
  };

  const onClickInactiveTab = () => {
    setWritersMode('inactive');
  };

  const handleNukeWriter = () => {
    setError(null);
    setIsNukingWriter(true);

    setTimeout(() => {
      deleteOrgArtist(AppState.pubOrganization.id.value, writerToNuke.id)
        .then((response) => {
          if (response.status === 200) {
            setIsNukingWriter(false);
            setWriterToNuke(null);
            alert('Writer Deleted Successfully!');
            window.location.reload();
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsNukingWriter(false);
          }
        })
        .catch((err) => {
          setIsNukingWriter(false);
          setError(err.message);
        });
    }, 2000);
  };

  const handleRevokeCredential = () => {
    setIsRevoking(true);
    setError('');

    setTimeout(() => {
      patchOrgArtistCredentialRevoked(AppState.pubOrganization.id.value, credToRevoke.id)
        .then((response) => {
          if (response.status === 200) {
            setIsRevoking(false);
            setCredToRevoke(null);
            alert('Credential Revoked Successfully!');
            window.location.reload();
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsRevoking(false);
          }
        })
        .catch((err) => {
          setIsRevoking(false);
          setError(err.message);
        });
    }, 800);
  };

  const handleDeleteCredential = () => {
    setIsDeleting(true);
    setError('');

    setTimeout(() => {
      deleteOrgArtistCredential(AppState.pubOrganization.id.value, credToDelete.id)
        .then((response) => {
          if (response.status === 200) {
            setIsDeleting(false);
            setCredToDelete(null);
            alert('Writer Credential Deleted Successfully!');
            window.location.reload();
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsDeleting(false);
          }
        })
        .catch((err) => {
          setIsDeleting(false);
          setError(err.message);
        });
    }, 800);
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Songwriters"
        primaryAction={{ method: addNew, text: '+ New' }}
        isLoadingPage={isLoadingOrganization}
        tabs={[
          { method: onClickActiveTab, text: 'Active', count: 0, isSelected: writersMode === 'active' },
          { method: onClickPendingTab, text: 'Pending', count: 0, isSelected: writersMode === 'pending' },
          { method: onClickInactiveTab, text: 'Inactive', count: 0, isSelected: writersMode === 'inactive' },
        ]}
      >
        {
          {
            active: <ActiveSongwriters setSelectedWriter={setSelectedWriter} />,
            pending: <PendingSongwriters />,
            inactive: <ExpiredSongwriters setSelectedWriter={setSelectedWriter} />,
          }[writersMode]
        }
      </PageCard>

      {selectedWriter && (
      <SongwriterProfileOverlay
        closeFunction={() => setSelectedWriter(null)}
        writer={selectedWriter}
        setWriterToNuke={setWriterToNuke}
        setCredToRevoke={setCredToRevoke}
        setCredToDelete={setCredToDelete}
      />
      )}

      {writerToNuke
      && (
      <DeleteConfirmationOverlay
        headerLabel="WARNING!!"
        header="Remove Writer & All Compositions"
        explanation={PUB_WRITER_EXPLANATIONS.nukeWriterWarning}
        closeFunction={() => setWriterToNuke(null)}
        handleDelete={handleNukeWriter}
        error={error}
        isLoading={isNukingWriter}
        confirmationPhrase={`${writerToNuke.firstName} ${writerToNuke.lastName}`}
        buttonText="Confirm and Remove Writer"
      />
      )}

      {credToRevoke
      && (
      <DeleteConfirmationOverlay
        headerLabel="Are you sure?"
        header="Revoke Verifiable Credential"
        explanation={PUB_WRITER_EXPLANATIONS.revokeCredentialConsequence}
        closeFunction={() => setCredToRevoke(null)}
        handleDelete={handleRevokeCredential}
        error={error}
        isLoading={isRevoking}
        confirmationPhrase="Revoke Credential"
        buttonText="Confirm and Revoke"
      />
      )}

      {credToDelete
      && (
      <DeleteConfirmationOverlay
        headerLabel="Are you really, REALLY sure?"
        header="Delete Verifiable Credential"
        explanation={PUB_WRITER_EXPLANATIONS.deleteCredentialConsequence}
        closeFunction={() => setCredToDelete(null)}
        handleDelete={handleDeleteCredential}
        error={error}
        isLoading={isDeleting}
        confirmationPhrase="Delete Credential"
        buttonText="Confirm and Delete"
      />
      )}

    </CorePublisherLayout>
  );
}

PublisherSongwriters.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherSongwriters;
